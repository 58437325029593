export default [ // eslint-disable-line

    // Generic
    {
        itemType: 'socialChannelItem',
        model: 'video.size',
        type: 'maxNumber',
        max: 85000000,
        dataType: 'asset',
        message: 'The uploaded video should be smaller than 80mb'
    },

    // Meta Dynamic Ad
    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'meta_dynamic_ad',
        model: 'message',
        multiLanguage: true,
        type: 'required',
        message: 'The Meta dynamic ad has to contain a valid message '
    },
    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'meta_dynamic_ad',
        model: 'headline',
        multiLanguage: true,
        type: 'required',
        message: 'The Meta dynamic ad has to contain a valid headline '
    },
    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'meta_dynamic_ad',
        model: 'link',
        multiLanguage: true,
        type: 'url',
        message: 'The Meta dynamic ad has to contain a valid URL and should include https:// '
    },
    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'meta_dynamic_ad',
        model: 'cta',
        multiLanguage: false,
        type: 'required',
        message: 'The Meta dynamic ad has to contain a valid Call to action value '
    },

    // Linkad
    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'facebook_linkad',
        model: 'message',
        multiLanguage: true,
        type: 'required',
        message: 'The Facebook linkad has to contain a valid message '
    },
    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'facebook_linkad',
        model: 'name',
        multiLanguage: true,
        type: 'required',
        message: 'The Facebook linkad has to contain a valid headline '
    },
    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'facebook_linkad',
        model: 'description',
        multiLanguage: true,
        type: 'required',
        message: 'The Facebook linkad has to contain a valid description '
    },
    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'facebook_linkad',
        model: 'link',
        multiLanguage: true,
        type: 'url',
        message: 'The Facebook linkad has to contain a valid URL and should include https:// '
    },

    // Instagram linkad
    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'instagram_linkad',
        model: 'message',
        multiLanguage: true,
        type: 'required',
        message: 'The Instagram linkad has to contain a valid post text '
    },
    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'instagram_linkad',
        model: 'link',
        multiLanguage: true,
        type: 'url',
        message: 'The Instagram linkad has to contain a valid URL and should include https:// '
    },

    // Carouselad
    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'facebook_carouselAd',
        model: 'message',
        multiLanguage: true,
        type: 'required',
        message: 'The Facebook carousel ad has to contain a valid post text '
    },
    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'facebook_carouselAd',
        model: 'name',
        multiLanguage: true,
        multiFrame: true,
        type: 'required',
        message: 'The Facebook carousel has to contain a valid headline '
    },
    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'facebook_carouselAd',
        model: 'description',
        multiLanguage: true,
        multiFrame: true,
        type: 'required',
        message: 'The Facebook carousel has to contain a valid description '
    },

    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'facebook_carouselAd',
        model: 'link',
        multiLanguage: true,
        multiFrame: true,
        type: 'url',
        message: 'The Facebook carousel ad needs to have a url starting with https:// '
    },

    // Instagram carousel ad
    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'instagram_carouselAd',
        model: 'message',
        multiLanguage: true,
        type: 'required',
        message: 'The Instagram carousel ad has to contain a valid post text '
    },

    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'instagram_carouselAd',
        model: 'link',
        multiLanguage: true,
        multiFrame: true,
        type: 'url',
        message: 'The Instagram carousel ad needs to have a url starting with https:// '
    },
    // Snapchat
    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'snapchat_singleAd',
        model: 'headline',
        multiLanguage: true,
        type: 'required',
        message: 'You need to add the headline to the post'
    },
    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'snapchat_singleAd',
        model: 'url',
        multiLanguage: true,
        type: 'required',
        message: 'You need to add the url to the post'
    },

    {
        itemType: 'socialChannelItem',
        channelTemplateIdentifier: 'snapchat_storiesAd',
        model: 'url',
        multiLanguage: true,
        type: 'required',
        multiFrame: true,
        message: 'You need to add a url to all the stories frames in Snapchat'
    },
    {
        itemType: 'socialChannelItem',
        dataType: 'item',
        channelTemplateIdentifier: 'snapchat_storiesAd',
        model: 'frames',
        multiLanguage: true,
        type: 'min:3,num',
        message: 'Your Snapchat stories ad needs to have at least three frames / stories'
    }
];
